//
// user.js
// Use this to write your custom JS
//

import $ from "jquery";
import Vue from 'vue/dist/vue.min.js';
// cookie
import 'vanilla-cookieconsent';

function initChatwoot(lang){
    window.chatwootSettings = {
        hideMessageBubble: false,
        type: "expanded_bubble",
        showPopoutButton: true,
        position: "right", // This can be left or right
        locale: lang, // Language to be set
        useBrowserLanguage: false, // Set widget language from user's browser
        darkMode: "auto", // [light, auto]
    };
    if(lang === 'en'){
        window.chatwootSettings.useBrowserLanguage = true;
    }
    $.get('/translate/chat?lang='+lang, function (chatwootIconTitle){
        window.chatwootSettings.launcherTitle = chatwootIconTitle;
        (function(d,t) {
            let BASE_URL="https://app.chatwoot.com";
            let g=d.createElement(t),s=d.getElementsByTagName(t)[0];
            g.src=BASE_URL+"/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g,s);
            g.onload=function(){
                window.chatwootSDK.run({
                    websiteToken: 'dQcEJ9AtyXJQbhNJNMt5Tvi5',
                    baseUrl: BASE_URL
                })
            }
        })(document,"script");
    });
}

function initFullstory(){
    // Whole your script here
    window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = 'T0M9A';
    window['_fs_namespace'] = 'FS';
    (function(m,n,e,t,l,o,g,y){
        if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
        g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
        o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
        y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
        g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
        g.anonymize=function(){g.identify(!!0)};
        g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
        g.log = function(a,b){g("log",[a,b])};
        g.consent=function(a){g("consent",!arguments.length||a)};
        g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
        g.clearUserCookie=function(){};
        g.setVars=function(n, p){g('setVars',[n,p]);};
        g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
        if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
        g._v="1.3.0";
    })(window,document,window['_fs_namespace'],'script','user');

}

$(document).ready(function (){

    let langmap = {
        'www':'en',
        'ar': 'ar',
        'nl': 'nl',
        'fr': 'fr',
        'de': 'de',
        'hi': 'hi',
        'it': 'it',
        'ja': 'ja',
        'ko': 'ko',
        'pt': 'pt',
        'ru': 'ru',
        'zh': 'zh',
        'es': 'es',
        'ml': 'ml',
        'ca': 'ca',
        'el': 'el',
        'ro': 'ro',
        'ta': 'ta',
        'fa': 'fa',
        'zh-tw': 'zh_TW',
        'vi': 'vi',
        'da': 'da',
        'tr': 'tr',
        'cs': 'cs',
        'fi': 'fi',
        'id': 'id',
        'sv': 'sv',
        'hu': 'hu',
        'no': 'no',
        'zh-cn': 'zh_CN',
        'pl': 'pl'
    }

    let host = window.location.host
    let subdomain = host.split('.')[0];
    window.sdlang = 'en';
    if(subdomain && subdomain in langmap){
        window.sdlang = langmap[subdomain];
    }

    $.get('/translate/cookie?lang='+window.sdlang, function (languageJson){
        let cookieconsent = initCookieConsent();
        // run plugin with config object
        cookieconsent.run({
            autorun: true,
            current_lang: window.sdlang,
            autoclear_cookies: true,
            page_scripts: true,
            hide_from_bots: true,
            force_consent: true,
            auto_language: false,
            gui_options: {
                consent_modal: {
                    layout: 'box',               // box/cloud/bar
                    position: 'middle center',     // bottom/middle/top + left/right/center
                    transition: 'zoom',           // zoom/slide
                    swap_buttons: true            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'box',                 // box/bar
                    // position: 'left',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },

            onFirstAction: function(user_preferences, cookie){
                // callback triggered only once
            },

            onAccept: function (cookie) {
                if (cookieconsent.allowedCategory('chat')) {
                    console.log('Consent for chat received.');
                    initChatwoot(window.sdlang);
                }else{
                    console.log('Consent for chat not received. Clear cookies to select again.');
                }

                if (cookieconsent.allowedCategory('analytics')) {
                    console.log('Consent for analytics received.');
                    initFullstory(window.sdlang);
                }else{
                    console.log('Consent for analytics not received. Analytics disabled. Clear cookies to select again.');
                }
                // ... cookieconsent accepted
            },

            onChange: function (cookie, changed_preferences) {
                // ... cookieconsent preferences were changed
            },

            languages: languageJson
        });

    });

    new Vue({
        el: '#vue-pricing',
        data: {
            stripe: { // stripe price plan id
                cloud: '',
                dedicated: '',
                privatecloud: ''
            },
            paypal: { // paypal price plan id
                cloud: '',
                dedicated: '',
                privatecloud: ''
            },
            flagClass: 'flag-icon-gb',
            price: {
                cloud: '',
                dedicated: '',
                privatecloud: ''
            },
            priceInt: {
              cloud: 0,
              dedicated: 0,
              privatecloud: 0
            },
            users: {
                cloud: '',
                dedicated: '',
                privatecloud: ''
            },
            subscriptionidx: {
                cloud: 0, // stripe subscription id
                dedicated: 0,
                privatecloud: 0
            },
            subscriptionsUsers: {
                cloud: [], // stripe subscription id
                dedicated: [],
                privatecloud: []
            },
            subscriptionidxPaypal: {
              cloud: 0, // stripe subscription id
              dedicated: 0,
              privatecloud: 0
            },
            subscriptionsUsersPaypal: {
              cloud: [], // stripe subscription id
              dedicated: [],
              privatecloud: []
            },
            period: 'monthly'
        },
        computed: {
            storage: function(){

                let cloudUsers = this.users.cloud.split('-');
                let storageCloud = parseInt(cloudUsers[0]) * 10;

                let dedicatedUsers = this.users.dedicated.split('-');
                let storageDedicated = parseInt(dedicatedUsers[0]) * 10;

                let privateUsers = this.users.privatecloud.split('-');
                let storagePrivateCloud = parseInt(privateUsers[0]) * 10;

                return {
                    cloud: storageCloud,
                    dedicated: storageDedicated,
                    privatecloud: storagePrivateCloud,
                }
            },
            maxusers: function(){
                return {
                    cloud: this.users.cloud < 200 ? this.users.cloud : 200,
                    dedicated: this.users.dedicated < 200 ? this.users.dedicated : 200,
                    privatecloud: this.users.privatecloud < 200 ? this.users.privatecloud : 200,
                }
            }
        },
        methods: {

            changePeriod: function(period) {
                console.log(period);
                this.period = period;
                this.priceChange('cloud');
                this.priceChange('dedicated');
                this.priceChange('privatecloud');
            },
            priceChange: function(type){
                let index = this.subscriptionidx[type];
                this.priceChangeIndex(index, type);
                this.initPayPalButton(type);
            },
            priceChangeIndex: function(index, type){
                let vals = this.subscriptionsUsers[type][index];
                if(this.period === 'yearly'){
                    this.price[type] = vals.priceYearly;
                    this.priceInt[type] = vals.priceYearlyInt;
                    this.stripe[type] = vals.stripeYearly;
                    this.paypal[type] = vals.paypalYearly;
                }else if(this.period === 'monthly'){
                    this.price[type] = vals.price;
                    this.priceInt[type] = vals.priceInt;
                    this.stripe[type] = vals.stripe;
                    this.paypal[type] = vals.paypal;
                }

                this.users[type] = vals.users;
                this.initPayPalButton(type);
            },
            signupPlanStripe: function(type) {
              let plan = this.stripe[type];
              //ga('send', 'event', 'Subscribe', 'click', plan);
              let locale = window.location.hostname.split('.')[0];
              let acceptable_lang_vals = "bg,cs,da,de,el,en,en-GB,es,es-419,et,fi,fil,fr,fr-CA,hr,hu,id,it,ja,ko,lt,lv,ms,mt,nb,nl,pl,pt,pt-BR,ro,ru,sk,sl,sv,th,tr,vi,zh,zh-HK,zh-TW".split(',');
              let acceptable_lang_keys = acceptable_lang_vals.toLocaleString().split(',');
              let acceptable_lang = acceptable_lang_vals.map(function(obj,index){
                  let myobj = {};
                  myobj[acceptable_lang_keys[index]] = obj;
                  return myobj;
              });
              if(acceptable_lang_keys.includes(locale)){
                  locale = acceptable_lang[locale];
              }

              if(locale === 'www' || locale === 'bigbluemeeting'){
                  locale = 'auto';
              }

              let qty = 1;
              if (type == 'privatecloud') {
                qty = parseInt(this.users[type].split('-')[0]);
              }
              console.log('signupPlanStripe', type, plan, qty, locale);
              window.stripe.redirectToCheckout({
                lineItems: [{
                  // Replace with the ID of your plan
                  price: plan,
                  quantity: qty,
                }],
                locale: locale,
                mode: 'subscription',
                successUrl: window.location.protocol + '//' + window.location.hostname + '/success',
                cancelUrl: window.location.href,
                billingAddressCollection: 'required',
              }).then(function(result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                let message = result.error.message;
              });

            },
            initPayPalButton: function(type) {
              let plan = this.paypal[type];
              $('#paypal-button-container-' + type).empty();
              let qty = 1;
              if (type == 'privatecloud') {
                qty = this.users[type].split('-')[0];
              }
              console.log('initPayPalButton', type, plan, qty);

              paypal.Buttons({
                style: {
                  shape: 'sharp',
                  tagline: false,
                  color: 'gold',
                  layout: 'horizontal',
                  label: 'subscribe',
                  disableMaxWidth: true
                },
                createSubscription: function(data, actions) {
                  return actions.subscription.create({
                    /* Creates the subscription */
                    plan_id: plan,
                    quantity: qty
                  });
                },
                onApprove: function(data, actions) {
                  alert(data.subscriptionID); // You can add optional success message for the subscriber here
                  window.location.href = '/success';
                }
              }).render('#paypal-button-container-' + type); // Renders the PayPal button
                //ga('send', 'event', 'Subscribe', 'click', plan);
            },
            getFlag: function() {

                const url = new URL(window.location.href);
                let sub_domain = url.host.split('.')[0];
                let map = {
                    'www': 'gb',
                    'de': 'de',
                    'ar': 'sa',
                    'bn': 'bd',
                    'zh-cn': 'cn',
                    'nl': 'nl',
                    'fr': 'fr',
                    'iw': 'il',
                    'hi': 'in',
                    'id': 'id',
                    'it': 'it',
                    'ja': 'jp',
                    'pl': 'pl',
                    'pt': 'br',
                    'ru': 'ru',
                    'es': 'es',
                    'tr': 'tr',
                    'vi': 'vn'
                };
                if (map[sub_domain] != null) {
                    this.flagClass = 'flag-icon-' + map[sub_domain];
                } else {
                    this.flagClass = 'flag-icon-gb';
                }
            },
            filterDuplicates: function(arr) {
                return arr.filter((el, index, self) => self.indexOf(el) === index);
            },

            fixLowercaseProperties: function(arr) {
                return arr.map((el) => {
                    if (!el ||
                        el.indexOf('-') === -1 ||
                        el.toLowerCase() !== el
                    ) {
                        return el;
                    }

                    const splitEl = el.split('-');
                    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
                });
            },

            getUserLocales: function() {
                let languageList = [];

                if (typeof window !== 'undefined') {
                    if (window.navigator.languages) {
                        languageList = languageList.concat(window.navigator.languages);
                    }
                    if (window.navigator.language) {
                        languageList.push(window.navigator.language);
                    }
                    if (window.navigator.userLanguage) {
                        languageList.push(window.navigator.userLanguage);
                    }
                    if (window.navigator.browserLanguage) {
                        languageList.push(window.navigator.browserLanguage);
                    }
                    if (window.navigator.systemLanguage) {
                        languageList.push(window.navigator.systemLanguage);
                    }
                }

                languageList.push('en-US'); // Fallback

                return this.fixLowercaseProperties(this.filterDuplicates(languageList));
            },
            getPricingData: function(){
                var self = this;
                $.get('/pricing.json',function (data) {
                    data = data['data'];
                    let USDollar = new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      useGrouping: 'auto',
                      maximumFractionDigits: 0 // ex. 2 -> .00
                    });
                    data.forEach(el => {
                        let slug = el.slug;
                        let pricing = el.pricing;
                        let users = [];
                        let prices = [];
                        let pricesInt = [];
                        let stripe = [];
                        let paypal = [];
                        let pricesYearly = [];
                        let pricesYearlyInt = [];
                        let stripeYearly = [];
                        let paypalYearly = [];
                        pricing.forEach(price => {
                            users.push(price.users);
                            prices.push(USDollar.format(price.price));
                            pricesInt.push(price.price);
                            stripe.push(price.stripePriceId);
                            paypal.push(price.paypalPriceId);
                            pricesYearly.push(USDollar.format(price.priceYearly));
                            pricesYearlyInt.push(price.priceYearly);
                            stripeYearly.push(price.stripePriceIdYearly);
                            paypalYearly.push(price.paypalPriceIdYearly);
                        });


                        for (let index = 0; index < users.length; index++) {
                            self.subscriptionsUsers[slug].push( {
                                users: users[index],
                                stripe: stripe[index],
                                paypal: paypal[index],
                                price: prices[index],
                                priceInt: pricesInt[index],
                                stripeYearly: stripeYearly[index],
                                paypalYearly: paypalYearly[index],
                                priceYearly: pricesYearly[index],
                                priceYearlyInt: pricesYearlyInt[index],
                                id: index
                            });
                        }

                        //self.stripe[slug] = stripe[0];
                        self.priceChangeIndex(0, slug); // set first item.
                    });
                });
            },
            contact: function (type){

              let message = 'Hello,\n\n';
              message  += 'I am interested in '+type+' plan for BigBlueButton';
              message += ' with '+this.users[type]+' users ';
              message += 'for '+this.price[type]+' USD ' + this.period + '.\n\n';
              message += "I would like to pay for this using a bank transfer. \n\n";

              message += "Please send me an invoice with the following business information: ";

              window.location.href = '/bank?message='+encodeURIComponent(message) + '&subject=' + encodeURIComponent('BigBlueButton Banking Invoice Request');
            }

        },
        mounted: function() {
            //this.getFlag();
            let self = this;
            this.getPricingData();
            //this.initLightgallery();

        }
    });
});

